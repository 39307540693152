<template>
    <div class="page-content">
        <page-breadcrumb title="User Balance" class="mb-3" />
        <section class="py-1">
            <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
            <b-card>
                <data-table-ssr id="tutor_levelup_list" ref="tutor_levelup_list" :limit-base="9"
                    pagination
                    :get-list-fn="getList" :columns="tutor_levelup_fields"
                />
            </b-card>
        </section>
    </div>
</template>
<script>
import service from "../service"
const status_options = [
    {text: "COMPLETED", value: 1},
    {text: "PENDING", value: 2}
]
const tutor_levelup_fields = [
  { label: 'User ID', field: 'user_id' },
  { label: 'Token ID', field: 'item_id' },
  { label: 'Level', field: 'level' },
  { label: 'Token Balance', field: 'token_balance' },
  { label: 'Token Consumed', field: 'token_consumed' },
  { label: 'Down Time', field: 'down_time' },
  {
    label: 'Status', field: 'status', type: 'array', input_type: 'select', options: status_options,
  },
  { label: 'Occur Time', field: 'occur_time', input_type: 'datetime' },
];
const filter_schema = [
  { cols: 5, fields: [{ label: 'User ID', field: 'user_id' }] },
  { cols: 5, fields: [{ label: 'Token ID', field: 'item_id'}] },
  { cols: 6, fields: [{ label: 'Occur Time', field: 'time_range', input_type:"datetime_range"}] }
];
export default {
    data(){
        return {
            export_data: [],
            filter: {
                time_range:[
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                ]
            },
            tutor_levelup_fields,
            filter_schema,
            statusObj:{
                1: "COMPLETED",
                2: "PENDING"
            }
        }
    },
     watch: {
        current_page() {
            this.getList();
        },
        filter: {
            handler() {
                if (this.filter.time_range.match(/to/i)){
                    this.$refs.tutor_levelup_list.getList();
                }
            },
            deep: true,
        },
    },
    
    methods: {
        async getList({ limit, page, query }) {
            let list = [], total = 0;
            let response = await service.getList({ query: { ...query, ...this.filter }, page, limit });
            if (response.data.type === "DATA") {
                list = response.data.data.list;
                total = response.data.data.total;
            }
            return { list, total };
        },
    }
}
</script>